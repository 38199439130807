html{
  scroll-behavior: smooth;
}
*{
  margin: 0;
  padding: 0;
}
body{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
/*Navbar*/
.navbar_main{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 15px 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #eee;
}
.navbar_inner{
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
  clear: both;
}
.navbar_logo{
  width: 250px;
  float: left;
}
.navbar_right{
  display: inline-flexbox;
  float: right;
  position: relative;
  top: 14px;
}
.navbar_content{
  display: inline-block;
}
.navbar_content ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.navbar_content ul li{
  display: inline-block;
  margin-right: 30px;
}
.navbar_content ul li:last-child{
  margin-right: 0;
}
.navbar_content ul li a{
  text-decoration: none;
  color: #000;
  transition: 0.3s ease-in-out;
  padding-bottom: 2px;
}
.navbar_content ul li a:hover{
  border-bottom: 2px solid #2fb347;
  color: #2fb347;
}
.navbar_secondary{
  display: inline-block;
  width: 200px;
  margin-left: 20px;
}
.navbarSmall_right{
  display: none;
}
/*Header*/
.header{
  width: 100%;
  margin: 0 0 20px 0;
  padding: 0;
  height: auto;
  text-align: center;
}
.header p{
  font-size: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.header p span{
  font-weight: normal;
  font-family: 'Poppins', sans-serif;
  color: #2fb347;
}
.header hr{
  height: 2px;
  width: 50px;
  background-color: #2fb347;
  margin: 0 auto;
  padding: 0;
}
/*Home*/
.home{
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-image: url('../src/images/gallery_11.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.home_inner{
  width: 90%;
  margin: 0 auto;
}
.home_content{
  background-color: rgb(47, 179, 71, 0.9);
  width: 400px;
  padding: 30px;
  color: #fff;
}
.home_content h2{
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  padding: 0;
  margin: 0 0 10px 0;
}
.home_content .link{
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0px 0 0px 0;
  margin: 0px 0 5px 0;
  font-size: 22px;
  text-decoration: none;
  display: block;
  color: #fff;
  animation: color 2s ease-in-out infinite;
  
}
#whatsapp{
  animation: color 2.5s ease-in-out infinite;
}
@keyframes color{
  0%{
    margin-left: 0px;
  } 
  25%{
    margin-left: 0px;
  }
  50%{
    margin-left: 20px;
  }
  75%{
    margin-left: 0px;
  }
  100%{
    margin-left: 0px;
  }
}
.home_content .rera{
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0;
  margin: 0 0 0px 0;
  font-size: 16px;
  font-weight: bold;
}
.home_content .button{
  width: 200px;
  display: block;
  text-align: center;
  margin: 20px auto 0 auto;
  font-size: 14px;
  text-decoration: none;
  color: #2fb347;
  background-color: #fff;
  padding: 10px 20px;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
}
.home_content .button:hover{
  color: #2fb347;
  text-decoration: none;
}
/*Features*/
.features{
  width: 100%;
  margin: 0;
  padding: 40px 0;
  height: auto;
}
.features_details{
  display: flex;
  justify-content: center;
  align-items: center;
}
.features_single{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.features_inner{
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
}
.features_single_detail{
  display: inline-flex;
  padding: 10px;
}
.features_single_count{
  display: inline-block;
  position: relative;
}
.features_single_count span{
  width: 48px;
  display: block;
  position: relative;
  top: -15px;
  padding: 0 10px 0 5px;
  font-size: 50px;
  color: #666;
  font-family: 'Poppins', sans-serif;
}
.features_single_content{
  display: inline-block;
}
.features_single_content h3{
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
}
.features_single_content p{
  color: #666;
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
}
.features_layout{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
/*Location*/
.location{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 40px 0;
}
.location_inner{
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 45% 55%;
}
.location_1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
}
.location_1 p{
  padding: 0;
  margin: 0;
  font-size: 28px;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  color: #2fb347;
}
.location_1 ul{
  margin: 0 0 30px 30px;
  padding: 0;
  list-style-type: disc;
}
.location_1 ul li{
  font-size: 18px;
  font-family: 'Source Sans Pro', sans-serif;
}
.location_header{
  width: 100%;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
.location_2{
  margin: 20px;
}
/*About Us*/
.about_main{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 40px 0;
  font-family: 'Source Sans Pro', sans-serif;
}
.about_main_inner{
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
}
.about_main_1{
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_main_1 p{
  font-size: 18px;
}
.about_main_2{
  margin: 20px;
}
video{
  width: 100%;
  outline: none;
  outline-color: #2fb347;
}
/*Gallery*/
.gallery{
  width: 100%;
  margin: 0;
  padding: 40px 0 0 0;
  height: auto;
}
.gallery_inner{
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.gallery_inner img{
  height: 280px;
  width: 100%;
  object-fit: cover;
}
/*Contact*/
.contact{
  width: 100%;
  margin: 0;
  padding: 0;
  height: auto;
  background-color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
}
.contact_inner{
  width: 100%;
  display: grid;
  grid-template-columns: 60% 40%;
}
.contact_1{
  width: 80%;
  padding: 40px 0;
  margin: 0 auto;
}
.contact_1 h2{
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}
.contact_1 p{
  padding: 0;
  margin: 10px 0;
}
.contact_1 form{
  width: 80%;
  margin: 0 auto;
}
.contact_1 form label{
  width: 100%;
  color: #666;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0;
  margin: 0px 0 0 0;
}
.contact_1 form input{
  width: 100%;
  border: 1px solid #ccc;
  font-size:14px;
  outline-color: #2fb347;
  margin: 5px 0 10px 0;
  padding: 10px 15px;
}
.contact_1 form textarea{
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  font-size:14px;
  outline-color: #2fb347;
  margin: 5px 0 10px 0;
  padding: 10px 15px;
}
.contact_1 form button{
  width: 40%;
  background-color: #2fb347;
  color: #fff;
  float: right;
  border-radius: 0;
  font-size: 15px;
  padding: 10px 15px;
}
.contact_2{
  width: 100%;
  padding: 0px 0px;
  background-color: #2fb347;
}
.contact_2_inner{
  width: 80%;
  padding: 30px 0;
  margin: 0 auto;
}
.contact_2_inner h2{
  margin: 0 0 20px 0;
  padding: 0;
  color: #fff;
  font-size: 35px;
  font-family: 'Poppins', sans-serif;
}
.contact_2_inner p{
  padding: 0;
  color: #ddd;
  margin: 10px 0;
}
.contact_2_header span{
  padding: 0;
  color: #ddd;
  margin: 0;
  font-size: 16px;
}
.contact_2_single{
  margin-bottom: 10px;
}
.gmap_canvas iframe{
  width: 100%;
  height: 300px;
  margin-top: 20px;
}
.contact_2_single p{
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 22px;
}
/*Footer*/
.footer{
  width: 100%;
  height: auto;
  margin: 0;
  padding: 30px 0;
  background-color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
}
.footer_inner{
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer_heading p{
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}
.footer_heading1 p{
  padding: 0;
  margin: 10px 0 10px 0;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}
.footer_1{
  margin: 0 20px;
}
.footer_1 img{
  width: 120px;
}
.footer_1 p{
  font-size: 14px;
  margin: 10px 0;
  padding: 0;
}
.footer_2 ul{
  list-style-type: none;
  margin: 0px 0 20px 0;
  padding: 0;
  text-align: center;
}
.footer_2 ul li{
  display: list-item;
}
.footer_2 ul li a{
  text-decoration: none;
  color: #000;
  font-size: 15px;
  transition: 0.3s ease-in-out;
}
.footer_2 ul li a:hover{
  color: #2fb347;
}
.footer_3 ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.footer_3 ul li{
  display: list-item;
}
.footer_3 ul li a{
  text-decoration: none;
  color: #000;
  font-size: 15px;
  transition: 0.3s ease-in-out;
}
.footer_3 ul li a:hover{
  color: #2fb347;
}
.footer_3 .social{
  list-style-type: none;
  margin: 0px 0 0 0;
  padding: 0;
  text-align: center;
}
.footer_3 .social li{
  margin: 0 15px;
  display: inline-block;
}
.footer_3 .social li a{
  text-decoration: none;
  color: #000;
  font-size: 22px;
  transition: 0.3s ease-in-out;
}
.footer_3 .social li a:hover{
  color: #2fb347;
}
/*Copyright*/
.copyright{
  width: 100%;
  height: auto;
  padding: 10px 0;
  margin: 0;
  background-color: #fff;
}
.copyright_inner{
  width: 95%;
  margin: 0 auto;
}
.copyright_inner p{
  padding: 0;
  margin: 0;
  font-size: 13px;
  text-align: right;
}
/*Modal*/
.modal{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.modal_inner{
  width: 500px;
  background-color: #fff;
  position: relative;
}
.modal_inner p{
  background-color: #2fb347;
  color: #fff;
  padding: 10px 20px;
  margin: 0;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
}
.modal_inner span{
  position: absolute;
  top: -3px;
  right: 15px;
  color: #fff;
  font-size: 35px;
  cursor: pointer;
}
.modal_inner form{
  width: 90%;
  margin: 10px auto 20px auto;
}
.modal_inner form label{
  width: 100%;
  color: #666;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0;
  margin: 0px 0 0 0;
  font-size: 15px;
}
.modal_inner form input{
  width: 100%;
  border: 1px solid #ccc;
  font-size:14px;
  outline-color: #2fb347;
  margin: 5px 0 10px 0;
  padding: 7px 10px;
  font-size: 15px;
}
.modal_inner form button{
  width: 100%;
  background-color: #2fb347;
  color: #fff;
  border-radius: 0;
  margin-top: 10px;
  font-size: 15px;
  padding: 10px 10px;
  font-family: 'Poppins', sans-serif;
}
/*Privacy*/
.privacy{
  width: 100%;
  height: auto;
  margin: 20px 0 40px 0;
  padding: 0;
}
.privacy_inner{
  width: 80%;
  margin: 0 auto;
}
.privacy_inner span{
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin: 10px 0 5px 0;
  font-family: 'Poppins', sans-serif;
}
.privacy_inner p{
  width: 95%;
  text-align: justify;
  font-size: 15px;
  padding: 0;
  margin: 0 auto 5px auto;
  font-family: 'Poppins', sans-serif;
}
.privacy_inner ul{
  margin: 0 0 0 40px;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.privacy_inner ul li{
  width: 95%;
  margin: 0 auto;
  font-size: 15px;
}
/*--------------------TABLE VIEW--------------------*/
@media screen and (max-width: 1020px){
  /*Navbar*/
  .navbar_main{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 15px 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid #eee;
  }
  .navbar_inner{
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    clear: both;
  }
  .navbar_logo{
    width: 200px;
    float: left;
  }
  .navbar_right{
    display: none;
    float: none;
  }
  .menu{
    width: 22px;
    height: 20px;
    position: absolute;
    right: 40px;
    top: 60px;
    cursor: pointer;
  }
  .icon{
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 0;
  }
  .icon::before{
      content: '';
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 8px;
  }
  .icon::after{
      content: '';
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 16px;
  }
  .navbarSmall_right{
      display: none;
      background-color: #fff;
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 20px 0 10px 0;
      box-shadow: 0 8px 6px -6px black;
      font-family: 'Poppins', sans-serif;
      z-index: 999999;
  }
  .showSmallNavbar{
      display: block;
  }
  .navbarSmall_contents{
      display: inline-block;
      width: 100%;
      text-align: center;
  }
  .navbarSmall_contents ul{ 
      width: 100%;
      margin: 0;
      padding: 0px 0;
      display: inline-block;
      list-style-type: none;
      position: relative;
      top: -5px;
      background-color: #fff;
      text-align: center;
  }
  .navbarSmall_contents ul li{
      display: list-item;
      margin-right: 0px;
      margin-bottom: 7px;
  }
  .navbarSmall_contents ul li a{
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      transition: 0.3s ease-in-out;
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      font-family: 'Poppins', sans-serif;
      padding: 0 0 2px 4px;
  }
  .navbarSmall_contents ul li a:hover{
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      color: #000;
  }
  /*Header*/
  .header{
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    height: auto;
    text-align: center;
  }
  .header p{
    font-size: 28px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .header p span{
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: #2fb347;
  }
  .header hr{
    height: 2px;
    width: 50px;
    background-color: #2fb347;
    margin: 0 auto;
    padding: 0;
  }
  /*Home*/
  .home{
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-image: url('../src/images/gallery_11.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .home_inner{
    width: 90%;
    margin: 0 auto;
  }
  .home_content{
    background-color: rgb(47, 179, 71, 0.9);
    width: 60%;
    padding: 30px;
    color: #fff;
  }
  .home_content h2{
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    padding: 0;
    margin: 0 0 10px 0;
  }
  .home_content .link{
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 19px;
    display: block;
    background-color: transparent;
  }
  .home_content .rera{
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0 0 0px 0;
    font-size: 16px;
    font-weight: bold;
  }
  .home_content .button{
    width: 200px;
    display: block;
    text-align: center;
    margin: 20px auto 0 auto;
    font-size: 14px;
    text-decoration: none;
    color: #2fb347;
    background-color: #fff;
    padding: 10px 20px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .home_content .button:hover{
    color: #2fb347;
    text-decoration: none;
  }
  /*Features*/
  .features{
    width: 100%;
    margin: 0;
    padding: 40px 0;
    height: auto;
  }
  .features_details{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .features_single{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .features_inner{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .features_single_detail{
    display: inline-flex;
    padding: 10px;
  }
  .features_single_count{
    display: inline-block;
    position: relative;
  }
  .features_single_count span{
    width: 38px;
    display: block;
    position: relative;
    top: -8px;
    padding: 0 10px 0 5px;
    font-size: 30px;
    color: #666;
    font-family: 'Poppins', sans-serif;
  }
  .features_single_content{
    display: inline-block;
  }
  .features_single_content h3{
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }
  .features_single_content p{
    color: #666;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .features_layout{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /*Location*/
  .location{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 40px 0;
  }
  .location_inner{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .location_1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
  }
  .location_1 p{
    padding: 0;
    margin: 0;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #2fb347;
  }
  .location_1 ul{
    margin: 0 0 30px 30px;
    padding: 0;
    list-style-type: disc;
  }
  .location_1 ul li{
    font-size: 18px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .location_header{
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .location_2{
    margin: 20px 10px;
  }
  /*About Us*/
  .about_main{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 40px 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .about_main_inner{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .about_main_1{
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about_main_1 p{
    font-size: 16px;
    text-align: justify;
  }
  .about_main_2{
    margin: 20px 0;
  }
  video{
    width: 100%;
    outline: none;
    outline-color: #2fb347;
  }
  /*Gallery*/
  .gallery{
    width: 100%;
    margin: 0 auto;
    padding: 40px 0 20px 0;
    height: auto;
  }
  .gallery_inner{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .gallery_inner img{
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  /*Contact*/
  .contact{
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    background-color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .contact_inner{
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
  .contact_1{
    width: 80%;
    padding: 40px 0;
    margin: 0 auto;
  }
  .contact_1 h2{
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }
  .contact_1 p{
    padding: 0;
    margin: 10px 0;
  }
  .contact_1 form{
    width: 80%;
    margin: 0 auto;
  }
  .contact_1 form label{
    width: 100%;
    color: #666;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0px 0 0 0;
  }
  .contact_1 form input{
    width: 100%;
    border: 1px solid #ccc;
    font-size:14px;
    outline-color: #2fb347;
    margin: 5px 0 10px 0;
    padding: 10px 15px;
  }
  .contact_1 form textarea{
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    font-size:14px;
    outline-color: #2fb347;
    margin: 5px 0 10px 0;
    padding: 10px 15px;
  }
  .contact_1 form button{
    width: 100%;
    background-color: #2fb347;
    color: #fff;
    float: none;
    border-radius: 0;
    font-size: 15px;
    padding: 10px 15px;
  }
  .contact_2{
    width: 100%;
    padding: 0px 0px;
    background-color: #2fb347;
  }
  .contact_2_inner{
    width: 60%;
    padding: 30px 0;
    margin: 0 auto;
  }
  .contact_2_inner h2{
    margin: 0 0 20px 0;
    padding: 0;
    color: #fff;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  .contact_2_inner p{
    padding: 0;
    color: #ddd;
    margin: 10px 0;
  }
  .contact_2_header span{
    padding: 0;
    color: #ddd;
    margin: 0;
    font-size: 14px;
  }
  .contact_2_single{
    margin-bottom: 10px;
  }
  .gmap_canvas iframe{
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
  .contact_2_single p{
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 18px;
  }
  /*Footer*/
  .footer{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 30px 0;
    background-color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .footer_inner{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  .footer_heading p{
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .footer_heading1 p{
    padding: 0;
    margin: 10px 0 10px 0;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .footer_1{
    margin: 0 20px;
    text-align: center;
  }
  .footer_1 img{
    width: 100px;
  }
  .footer_1 p{
    font-size: 14px;
    margin: 10px auto;
    padding: 0;
    width: 50%;
  }
  .footer_2 ul{
    list-style-type: none;
    margin: 0px 0 20px 0;
    padding: 0;
    text-align: center;
  }
  .footer_2 ul li{
    display: list-item;
  }
  .footer_2 ul li a{
    text-decoration: none;
    color: #000;
    font-size: 14px;
    transition: 0.3s ease-in-out;
  }
  .footer_2 ul li a:hover{
    color: #2fb347;
  }
  .footer_3 ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .footer_3 ul li{
    display: list-item;
  }
  .footer_3 ul li a{
    text-decoration: none;
    color: #000;
    font-size: 15px;
    transition: 0.3s ease-in-out;
  }
  .footer_3 ul li a:hover{
    color: #2fb347;
  }
  .footer_3 .social{
    list-style-type: none;
    margin: 0px 0 0 0;
    padding: 0;
    text-align: center;
  }
  .footer_3 .social li{
    margin: 0 15px;
    display: inline-block;
  }
  .footer_3 .social li a{
    text-decoration: none;
    color: #000;
    font-size: 22px;
    transition: 0.3s ease-in-out;
  }
  .footer_3 .social li a:hover{
    color: #2fb347;
  }
  /*Copyright*/
  .copyright{
    width: 100%;
    height: auto;
    padding: 10px 0;
    margin: 0;
    background-color: #fff;
  }
  .copyright_inner{
    width: 95%;
    margin: 0 auto;
  }
  .copyright_inner p{
    padding: 0;
    margin: 0;
    font-size: 13px;
    text-align: center;
  }
}
/*--------------------MOBILE VIEW-------------------*/
@media screen and (max-width: 600px){
  /*Navbar*/
  .navbar_main{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 15px 0;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    position: relative;
    border-bottom: 1px solid #eee;
  }
  .navbar_inner{
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    clear: both;
  }
  .navbar_logo{
    width: 200px;
    float: left;
  }
  .navbar_right{
    display: none;
    float: none;
  }
  .menu{
    width: 22px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 60px;
    cursor: pointer;
  }
  .icon{
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 0;
  }
  .icon::before{
      content: '';
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 8px;
  }
  .icon::after{
      content: '';
      width: 22px;
      height: 3px;
      background-color: #000;
      border-radius: 20px;
      position: absolute;
      top: 16px;
  }
  .navbarSmall_right{
      display: none;
      background-color: #fff;
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 20px 0 10px 0;
      box-shadow: 0 8px 6px -6px black;
      font-family: 'Poppins', sans-serif;
      z-index: 999999;
  }
  .showSmallNavbar{
      display: block;
  }
  .navbarSmall_contents{
      display: inline-block;
      width: 100%;
      text-align: center;
  }
  .navbarSmall_contents ul{ 
      width: 100%;
      margin: 0;
      padding: 0px 0;
      display: inline-block;
      list-style-type: none;
      position: relative;
      top: -5px;
      background-color: #fff;
      text-align: center;
  }
  .navbarSmall_contents ul li{
      display: list-item;
      margin-right: 0px;
      margin-bottom: 7px;
  }
  .navbarSmall_contents ul li a{
      text-decoration: none;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      transition: 0.3s ease-in-out;
      border-left: 2px solid transparent;
      border-bottom: 2px solid transparent;
      font-family: 'Poppins', sans-serif;
      padding: 0 0 2px 4px;
  }
  .navbarSmall_contents ul li a:hover{
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      color: #000;
  }
  /*Header*/
  .header{
    width: 80%;
    margin: 0 auto 20px auto;
    padding: 0;
    height: auto;
    text-align: center;
  }
  .header p{
    font-size: 25px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  .header p span{
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    color: #2fb347;
  }
  .header hr{
    height: 2px;
    width: 50px;
    background-color: #2fb347;
    margin: 0 auto;
    padding: 0;
  }
  /*Home*/
  .home{
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-image: url('../src/images/gallery_12.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .home_inner{
    width: 90%;
    margin: 0 auto;
  }
  .home_content{
    background-color: rgb(47, 179, 71, 0.9);
    width: 90%;
    margin: 0 auto;
    padding: 30px 10px;
    text-align: center;
    color: #fff;
  }
  .home_content h2{
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    padding: 0;
    margin: 0 0 10px 0;
  }
  .home_content .link{
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 18px;
    display: block;
    width: 100%;
    text-align: center;
    background-color: transparent;
  }
  .home_content .rera{
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0 0 0px 0;
    font-size: 14px;
    font-weight: bold;
  }
  .home_content .button{
    width: 80%;
    display: block;
    text-align: center;
    margin: 20px auto 0 auto;
    font-size: 13px;
    text-decoration: none;
    color: #2fb347;
    background-color: #fff;
    padding: 6px 0px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .home_content .button:hover{
    color: #2fb347;
    text-decoration: none;
  }
  /*Features*/
  .features{
    width: 100%;
    margin: 0;
    padding: 40px 0;
    height: auto;
  }
  .features_details{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .features_single{
    display: grid;
    grid-template-columns: 1fr;
  }
  .features_inner{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
  }
  .features_single_detail{
    display: inline-flex;
    padding: 10px;
  }
  .features_single_count{
    display: inline-block;
    position: relative;
  }
  .features_single_count span{
    width: 48px;
    display: block;
    position: relative;
    top: -12px;
    padding: 0 10px 0 5px;
    font-size: 40px;
    color: #666;
    font-family: 'Poppins', sans-serif;
  }
  .features_single_content{
    display: inline-block;
  }
  .features_single_content h3{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
  }
  .features_single_content p{
    color: #666;
    font-size: 15px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .features_layout{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  /*Location*/
  .location{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 40px 0;
  }
  .location_inner{
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
  }
  .location_1{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    padding: 10px;
  }
  .location_1 p{
    padding: 0;
    margin: 0;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #2fb347;
  }
  .location_1 ul{
    margin: 0 0 30px 30px;
    padding: 0;
    list-style-type: disc;
  }
  .location_1 ul li{
    font-size: 20px;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .location_header{
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }
  .location_2{
    margin: 20px 10px;
  }
  /*About Us*/
  .about_main{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 40px 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .about_main_inner{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
  }
  .about_main_1{
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about_main_1 p{
    font-size: 16px;
    text-align: justify;
  }
  .about_main_2{
    margin: 20px 0;
  }
  video{
    width: 100%;
    outline: none;
    outline-color: #2fb347;
  }
  /*Gallery*/
  .gallery{
    width: 90%;
    margin: 0 auto;
    padding: 40px 0 20px 0;
    height: auto;
  }
  .gallery_inner{
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  .gallery_inner img{
    height: 200px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  /*Contact*/
  .contact{
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    background-color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .contact_inner{
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
  .contact_1{
    width: 90%;
    padding: 40px 0;
    margin: 0 auto;
  }
  .contact_1 h2{
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }
  .contact_1 p{
    padding: 0;
    margin: 10px 0;
  }
  .contact_1 form{
    width: 90%;
    margin: 0 auto;
  }
  .contact_1 form label{
    width: 100%;
    color: #666;
    font-family: 'Source Sans Pro', sans-serif;
    padding: 0;
    margin: 0px 0 0 0;
  }
  .contact_1 form input{
    width: 100%;
    border: 1px solid #ccc;
    font-size:14px;
    outline-color: #2fb347;
    margin: 5px 0 10px 0;
    padding: 10px 15px;
  }
  .contact_1 form textarea{
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    font-size:14px;
    outline-color: #2fb347;
    margin: 5px 0 10px 0;
    padding: 10px 15px;
  }
  .contact_1 form button{
    width: 100%;
    background-color: #2fb347;
    color: #fff;
    float: none;
    border-radius: 0;
    font-size: 15px;
    padding: 10px 15px;
  }
  .contact_2{
    width: 100%;
    padding: 0px 0px;
    background-color: #2fb347;
  }
  .contact_2_inner{
    width: 80%;
    padding: 30px 0;
    margin: 0 auto;
  }
  .contact_2_inner h2{
    margin: 0 0 20px 0;
    padding: 0;
    color: #fff;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  .contact_2_inner p{
    padding: 0;
    color: #ddd;
    margin: 10px 0;
  }
  .contact_2_header span{
    padding: 0;
    color: #ddd;
    margin: 0;
    font-size: 15px;
  }
  .contact_2_single{
    margin-bottom: 10px;
  }
  .gmap_canvas iframe{
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
  .contact_2_single p{
    color: #fff;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
  /*Footer*/
  .footer{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 30px 0;
    background-color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
  }
  .footer_inner{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
  }
  .footer_heading p{
    padding: 0;
    margin: 0 0 5px 0;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .footer_heading1 p{
    padding: 0;
    margin: 10px 0 10px 0;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
  }
  .footer_1{
    margin: 0 20px;
    text-align: center;
  }
  .footer_1 img{
    width: 100px;
  }
  .footer_1 p{
    width: 80%;
    font-size: 14px;
    margin: 10px auto;
    padding: 0;
    text-align: center;
  }
  .footer_2 ul{
    list-style-type: none;
    margin: 0px 0 20px 0;
    padding: 0;
    text-align: center;
  }
  .footer_2 ul li{
    display: list-item;
  }
  .footer_2 ul li a{
    text-decoration: none;
    color: #000;
    font-size: 14px;
    transition: 0.3s ease-in-out;
  }
  .footer_2 ul li a:hover{
    color: #2fb347;
  }
  .footer_3 ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .footer_3 ul li{
    display: list-item;
  }
  .footer_3 ul li a{
    text-decoration: none;
    color: #000;
    font-size: 15px;
    transition: 0.3s ease-in-out;
  }
  .footer_3 ul li a:hover{
    color: #2fb347;
  }
  .footer_3 .social{
    list-style-type: none;
    margin: 0px 0 0 0;
    padding: 0;
    text-align: center;
  }
  .footer_3 .social li{
    margin: 0 15px;
    display: inline-block;
  }
  .footer_3 .social li a{
    text-decoration: none;
    color: #000;
    font-size: 22px;
    transition: 0.3s ease-in-out;
  }
  .footer_3 .social li a:hover{
    color: #2fb347;
  }
  /*Copyright*/
  .copyright{
    width: 100%;
    height: auto;
    padding: 10px 0;
    margin: 0;
    background-color: #fff;
  }
  .copyright_inner{
    width: 95%;
    margin: 0 auto;
  }
  .copyright_inner p{
    padding: 0;
    margin: 0;
    font-size: 13px;
    text-align: center;
  }
  /*Privacy*/
  .privacy{
    width: 100%;
    height: auto;
    margin: 20px 0 40px 0;
    padding: 0;
  }
  .privacy_inner{
    width: 90%;
    margin: 0 auto;
  }
  .privacy_inner span{
    font-size: 17px;
    font-weight: bold;
    display: block;
    margin: 10px 0 5px 0;
    font-family: 'Poppins', sans-serif;
  }
  .privacy_inner p{
    width: 95%;
    text-align: justify;
    font-size: 13px;
    padding: 0;
    margin: 0 auto 5px auto;
    font-family: 'Poppins', sans-serif;
  }
  .privacy_inner ul{
    margin: 0 0 0 20px;
    padding: 0;
    font-family: 'Poppins', sans-serif;
  }
  .privacy_inner ul li{
    width: 95%;
    margin: 0 auto;
    font-size: 13px;
  }
}